import React from 'react';
import PropTypes from 'prop-types';

import Wrapper, { Content } from './styled';

const Caption = ({ year, excerpt, align = 'left' }) => (
  <Wrapper>
    <Content align={align}>
      {year} | {excerpt}
    </Content>
  </Wrapper>
);

Caption.propTypes = {
  year: PropTypes.number.isRequired,
  align: PropTypes.string.isRequired,
  excerpt: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default Caption;
