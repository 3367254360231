import React from 'react';
import PropTypes from 'prop-types';
import { Outer, Inner } from 'containers/Wrapper';
import ParallaxBanner from 'containers/Parallax/ParallaxBanner';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import { Row, Col } from 'react-styled-flexboxgrid';
import CloudinaryImage from 'components/Image';
import InfoBox from 'components/InfoBox';

import {
  OuterWrapper,
  BannerWrapper,
  CalloutWrapper,
  InnerWrapper,
  StyledCallout,
  Stamp,
  InfoBoxWrapper
} from './styled';

const Block = ({ intl: { locale, messages } }) => (
  <OuterWrapper>
    <BannerWrapper>
      <ParallaxBanner
        layers={[
          {
            image: CloudinaryImage.getImageUrlById(
              'tripartism/worldwar_bg-top2'
            ),
            amount: 0.1,
            slowerScrollRate: false
          }
        ]}
        style={{
          zIndex: 0,
          height: '100%',
          width: '100%'
        }}
      >
        <CalloutWrapper>
          <InnerWrapper>
            <Row center="xs" style={{ flex: 1 }}>
              <Col xs={10} sm={8}>
                <Stamp>
                  <StyledCallout
                    size="large"
                    className="callout-with-fringes"
                    locale={locale}
                    text={{
                      article: messages.interface_masculineArticle,
                      in: messages.interface_in
                    }}
                    color="white"
                  >
                    <FormattedHTMLMessage id="tripartism_calloutWorld1919" />
                  </StyledCallout>
                </Stamp>
              </Col>
            </Row>
          </InnerWrapper>
        </CalloutWrapper>
      </ParallaxBanner>
    </BannerWrapper>
    <InfoBoxWrapper>
      <Outer>
        <Inner>
          <Row center="sm">
            <Col xs={12} sm={8} lg={6}>
              <InfoBox scroll size={{ sm: '20rem', md: '18rem', lg: '27rem' }}>
                <FormattedHTMLMessage id="tripartism_infoWw1" />
              </InfoBox>
            </Col>
          </Row>
        </Inner>
      </Outer>
    </InfoBoxWrapper>
  </OuterWrapper>
);

Block.propTypes = {
  intl: PropTypes.shape({
    locale: PropTypes.string,
    messages: PropTypes.shape({})
  })
};

Block.defaultProps = {
  intl: {
    locale: 'en',
    messages: {}
  }
};

export default injectIntl(Block);
