import React from 'react';
import { Outer, Inner } from 'containers/Wrapper';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'react-styled-flexboxgrid';
import Callout from 'components/Callout';
import { Wrapper } from './styled';

const Block = () => (
<Wrapper>
    <Outer>
      <Inner>
        <Row center="xs">
          <Col xs={12} sm={9} md={8} lg={8}>
          <Wrapper>
            <Callout size="small" stamp="circle">
              <FormattedMessage id="tripartism_calloutIloCreated" />
            </Callout>
          </Wrapper>
          </Col>
        </Row>
      </Inner>
    </Outer>
  </Wrapper>
);

export default Block;
