import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col } from 'react-styled-flexboxgrid';
import Slider from 'components/Slider';
import Slide from 'components/Slider/Slide';
import { Inner } from 'containers/Wrapper';
import getContentBySubstring from 'utils/getContentBySubstring';
import key from 'assets/images/small_key.svg';
import {
  Background,
  OuterWrapper,
  Heading,
  TopTear,
  Wrapper,
  QuotesWrapper,
  Keys,
  Key
} from './styled';

const Block = ({ intl: { messages } }) => (
  <Background>
    <TopTear />
    <OuterWrapper>
      <Inner>
        <Wrapper>
          <Row center="xs">
            <Col xs={11} sm={12}>
              <Heading>
                <FormattedMessage id="tripartism_whatIsSocialJustice" />
              </Heading>
            </Col>
          </Row>
          <Row center="xs">
            <Col xs={11} sm={7}>
              <QuotesWrapper>
                <Slider>
                  {getContentBySubstring(
                    messages,
                    'tripartism_quoteSocJusBody'
                  ).map((quote, i) => (
                    <Slide
                      key={quote}
                      root="tripartism_quoteSocJus"
                      index={i}
                    />
                  ))}
                </Slider>
              </QuotesWrapper>
            </Col>
          </Row>
          <Keys>
            <Key src={key} />
            <Key src={key} />
            <Key src={key} /> ̰
          </Keys>
        </Wrapper>
      </Inner>
    </OuterWrapper>
  </Background>
);

Block.propTypes = {
  intl: PropTypes.shape({}).isRequired
};

export default injectIntl(Block);
