import React from 'react';
import InfoCollage from 'modules/InfoCollage';

const collageLayers = [
  'tripartism/tripartism-commission-3',
  'tripartism/tripartism-commission-2',
  'tripartism/tripartism-commission-1'
];

const Block = () => (
  <InfoCollage
    collage={{
      layers: collageLayers,
      caption: {
        message: 'tripartism_imageCommissionCaption',
        colProps: {
          xs: 12,
          sm: 8,
          smOffset: 2,
          md: 6,
          mdOffset: 2
        }
      },
      colProps: {
        xs: 12,
        smOffset: 0,
        sm: 12,
        mdOffset: -1,
        md: 9,
        lgOffset: -2,
        lg: 10
      }
    }}
    infoBoxes={[
      {
        message: 'tripartism_infoUnrest',
        colProps: {
          xs: 12,
          smOffset: 1,
          sm: 9,
          mdOffset: 6,
          md: 5,
          lgOffset: 6,
          lg: 6
        }
      },
      {
        message: 'tripartism_infoFounders',
        colProps: {
          xs: 12,
          smOffset: 1,
          sm: 9,
          mdOffset: 6,
          md: 5,
          lgOffset: 6,
          lg: 6
        },
        justify: 'flex-end'
      }
    ]}
    voffset={{
      md: '15rem 0',
      lg: '20rem 0',
      sm: '35rem 0 30rem'
    }}
  />
);

export default Block;
