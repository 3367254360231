import React from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import { Inner, Outer } from 'containers/Wrapper';
import Quote from 'components/Quote';
import Wrapper from './styled.js';

const Block = () => (
  <Wrapper>
    <Outer>
      <Inner>
        <Row center="xs">
          <Col xs={11} sm={11} md={8}>
            <Quote
              quoteID="tripartism_keysQuote"
              captionID="tripartism_guyRyder"
              soundID="tripartism/quote-threekeys"
              imageID="tripartism/guy_ryder"
              imageWidth="250px"
            />
          </Col>
        </Row>
      </Inner>
    </Outer>
  </Wrapper>
);

export default Block;
