import styled, { css } from 'styled-components';
import Callout from 'components/Callout';
import { Outer } from 'containers/Wrapper';
import { serif } from 'styles/variables';
import media from 'styles/media';
import Tear from 'components/Tear';

export const OuterWrapper = styled.section`
  position: relative;
  min-height: 70rem;

  ${media.md`
    min-height: 60rem;
  `};

  ${media.sm`
    min-height: 55rem;
  `}

  ${media.portrait`
    min-height: 60rem;
  `}

  ${media.xs`
    min-height: initial;
    height: auto;
  `};
`;

export const BannerWrapper = styled(Outer)`
  padding-left: 0 !important;
  position: relative;
  height: 50rem;

  ${media.sm`
    height: 40rem;
  `};

  ${media.xs`
    height: 20rem;
    padding: 0;
    .parallax-banner-layer-0 {
      background-position: top 0 right 40% !important;
      top: 0 !important;
      bottom: 0 !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;
    }
  `};
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.6;
`;

export const CalloutWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const InnerWrapper = styled.div`
  position: relative;
  display: flex;
  width: inherit;
  height: inherit;
  justify-content: center;
  align-items: center;
`;

const decoratedText = css`
  position: absolute;
  top: 22%;
  color: white;
  height: 50px;
  width: 50px;
  font-family: ${serif};
  font-style: italic;
  font-size: 2.5rem;

  ${media.md`
    top: 10%;
  `};

  ${media.xs`
    display: none;
  `};
`;

export const StyledCallout = styled(Callout)`
  position: relative;
`;

export const BottomTear = styled(Tear)`
  transform: scaleX(-1);
  bottom: -2px !important;
`;

export const InfoBoxWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;

  ${media.xs`
    position: relative;
  `};
`;
