import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col } from 'react-styled-flexboxgrid';
import Callout from 'components/Callout';
import Image from 'components/Image';

import { Wrapper } from './styled';

const Block = () => (
  <Wrapper>
    <Row center="xs" style={{ width: '100%' }}>
      <Col xs={10} sm={8}>
        <Callout color="black">
          <FormattedMessage id="tripartism_calloutWildDream" />
        </Callout>
      </Col>
    </Row>
    <Row center="xs" style={{ width: '100%' }}>
      <Col xs={10} sm={8}>
        <Callout color="black">
          <Image id="tripartism/roosevelt-stamp" />
        </Callout>
      </Col>
    </Row>
  </Wrapper>
);

export default injectIntl(Block);
