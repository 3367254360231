import styled from 'styled-components';
import media from 'styles/media';

export const Wrapper = styled.section`
  min-height: 25rem;
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.sm`
    min-height: 15rem;
    margin: 4rem 0 0;
  `};

  ${media.xs`
    min-height: 8rem;
    height: auto;
    margin: 1rem 0;
  `};
`;

export default Wrapper;
