/* eslint-disable no-return-assign */
import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'modules/Timeline/Image';

class ImageLoader extends React.Component {
  state = {
    loaded: false
  };

  componentDidMount() {
    if (this.image && this.image.complete) {
      this.handleImageLoaded();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.loaded !== this.state.loaded;
  }

  handleImageLoaded = () => {
    if (!this.state.loaded) {
      this.setState({ loaded: true });
    }
  };

  render(){
    const { src, alt, Component, ...restProps } = this.props;

    if (!src) {
      return (
        <Component
          ref={el => this.image = el}
          style={this.state.loaded ? {opacity: 1} : {opacity: 0}}
          onLoad={this.handleImageLoaded}
          key={src}
          alt={alt}
          {...restProps}
        />);
    }

    return (
      <Component
        ref={el => this.image = el}
        src={src}
        key={src}
        onLoad={this.handleImageLoaded}
        alt={alt}
        {...restProps}
    />);
  }
}

ImageLoader.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  Component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

ImageLoader.defaultProps = {
  alt: 'image',
  src: '',
  Component: Image,
};

export default ImageLoader;
