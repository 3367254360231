import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { SlideBody, SlideCaption } from './styled';

const Slide = ({ root, index }) => (
  <div>
    <SlideBody>
      <FormattedMessage id={`${root}Body${index + 1}`} />
    </SlideBody>
    <SlideCaption>
      <FormattedMessage id={`${root}Caption${index + 1}`} />
    </SlideCaption>
  </div>
);

Slide.propTypes = {
  root: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired
}

export default Slide;
