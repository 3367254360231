import styled from 'styled-components';

import { black, sans, mobileImageHeight } from 'styles/variables';
import media from 'styles/media';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  min-height: 850px;
  position: relative;
  background-color: ${black};
  font-family: ${sans};
  overflow: hidden;

  ${media.xs`
    height: auto;
    flex-direction: column;
  `};
`;

export const LeftSide = styled.div`
  width: ${props => (props.full ? '100%' : '50%')};
  height: 100%;
  min-height: 100%;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  filter: brightness(80%);
  
  *::selection {
    user-select: none;
  }

  ${media.xs`
    width: 100%;
    position: relative;
    height: ${mobileImageHeight};
  `};
  
  .slide__left {
    .carousel-leave-active {
      z-index: -1;
    }
  }
`;

export const RightSide = styled.div`
  width: 50%;
  height: 100%;
  min-height: 100%;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  overflow: hidden;
  
  *::selection {
    user-select: none;
  }

  ${media.xs`
    display: none;
  `};
  
  .slide__left {
    .carousel-enter-active {
      z-index: 3;
    }
    .carousel-enter {
      z-index: 3;
    }
    .carousel-leave-active {
      z-index: 3;
    }
    .carousel-leave {
      z-index: 3;
    }
  }
  
  img {
    opacity: 0.5 !important;
    filter: blur(35px);
  }
`;

export const BlackBg = styled.div`
  background: #000;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
