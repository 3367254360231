import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Parallax from 'containers/Parallax';
import { Row, Col } from 'react-styled-flexboxgrid';
import { Inner, Outer } from 'containers/Wrapper';
import { Wrapper, StyledCallout, KeysWrapper, Keys, Key } from './styled';

const Block = ({intl: { locale }}) => (
  <Outer>
    <Inner>
      <Wrapper>
        <Row center="xs" style={{ flex: 1 }}>
          <Col xs={10} md={8} lg={10}>
            <StyledCallout size="large" color="rose" locale={locale}>
              <FormattedMessage id="tripartism_socialJustice" />
            </StyledCallout>
          </Col>
        </Row>
        <KeysWrapper>
          <Keys>
            <Parallax offsetYMax="300px" offsetYMin="0px" slowerScrollRate>
              <Key id="tripartism/bit-key" />
            </Parallax>
            <Parallax offsetYMax="300px" offsetYMin="-50px">
              <Key id="tripartism/ilo-key" />
            </Parallax>
            <Parallax offsetYMax="600px" offsetYMin="-300px">
              <Key id="tripartism/bit-key" />
            </Parallax>
          </Keys>
        </KeysWrapper>
      </Wrapper>
    </Inner>
  </Outer>
);

export default injectIntl(Block);
