/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import isIE11 from 'utils/isIE11';
import getVideoUrlById from 'utils/video';
import Modal from 'components/Modal';
import CloudinaryImage from 'components/Image';
import ImageLoader from 'modules/Timeline/ImageLoader';
import Player from 'components/Player';
import Controls, { Control, ControlBox } from 'components/Controls';
import PulsateCircle from 'modules/Timeline/PulsateCircle';
import LayoutSplitted from 'modules/Timeline/LayoutSplitted';
import Caption from 'modules/Timeline/Caption';
import Timeliny from 'modules/Timeline/Timeliny';
import { TimelinyWrapper } from 'modules/Timeline/Timeliny/styled';
import {
  LeftSide,
  RightSide,
  BlackBg
} from 'modules/Timeline/LayoutSplitted/styled';
import { Image, FullSizeImage, ImageBackground } from 'modules/Timeline/Image';
import {
  SeeThisPhotoVideo,
  SeeThisPhotoVideoText,
  Icon,
  Line,
  Paragraph
} from 'modules/Timeline/styled';
import InfoBox, {
  InfoBoxTitle,
  InfoBoxHead,
  InfoBoxDescription,
  InfoBoxBody
} from 'modules/Timeline/InfoBox';

import arrow from 'assets/images/timeline/arrow-in-circle.svg';

class Timeline extends React.Component {
  static sortByYear(data) {
    return data.sort((a, b) => a.year - b.year);
  }

  state = {
    showModal: false,
    direction: 'right',
    currentItemIndex: 0,
    items:
      this.props.data.length > 0 ? Timeline.sortByYear(this.props.data) : []
  };

  getTranslationTitleId = year => `tripartism_timeline${year}Heading`;

  getTranslationDescriptionId = year => `tripartism_timeline${year}Para`;

  getTranslationExcerptId = year => `tripartism_timeline${year}Caption`;

  handleOpenModal = () => {
    const documentGlobal = typeof document !== 'undefined' && document;
    if (!documentGlobal) {
      return;
    }
    document.body.classList.add('overflow');
    this.setState({ showModal: true });
  };

  handleCloseModal = () => {
    const documentGlobal = typeof document !== 'undefined' && document;
    if (!documentGlobal) {
      return;
    }
    document.body.classList.remove('overflow');
    this.setState({ showModal: false });
  };

  handleIndexChange = index => {
    let direction = null;
    if (this.displayTimelineInReverse()) {
      direction = index > this.state.currentItemIndex ? 'left' : 'right';
    } else {
      direction = index > this.state.currentItemIndex ? 'right' : 'left';
    }

    this.setState({ currentItemIndex: index, direction });
  };

  goPreviousItem = () => {
    const { items } = this.state;
    const { currentItemIndex } = this.state;
    const previousIndex = currentItemIndex - 1;
    const previousYear = items[previousIndex].year;

    this.child.goToYear(previousYear);
  };

  goNextItem = () => {
    const { items } = this.state;
    const { currentItemIndex } = this.state;
    const nextIndex = currentItemIndex + 1;
    const nextYear = items[nextIndex].year;

    this.child.goToYear(nextYear);
  };

  displayTimelineInReverse() {
    const {
      intl: { locale: language }
    } = this.props;
    return language === 'ar';
  }

  renderRingWithIconButton(isPhoto) {
    return (
      <SeeThisPhotoVideo onClick={this.handleOpenModal}>
        <PulsateCircle isPhoto={isPhoto} />
        <SeeThisPhotoVideoText>
          {isPhoto ? <FormattedMessage id="interface_seePhoto" /> : <FormattedMessage id="interface_seeVideo" />}
        </SeeThisPhotoVideoText>
      </SeeThisPhotoVideo>
    );
  }

  renderInfoBox = (item, firstItemId, lastItemId) => (
    <InfoBox>
      <InfoBoxHead>
        <Paragraph>{item.year}</Paragraph>
        <Line />
        {this.displayTimelineInReverse() ? (
          <Controls>
            <ControlBox>
              {firstItemId !== item.id && (
                <Control onClick={this.goNextItem}>
                  <Icon src={arrow} alt="left arrow" />
                </Control>
              )}
            </ControlBox>
            <ControlBox>
              {lastItemId !== item.id && (
                <Control right onClick={this.goPreviousItem}>
                  <Icon src={arrow} alt="right arrow" />
                </Control>
              )}
            </ControlBox>
          </Controls>
        ) : (
          <Controls>
            <ControlBox>
              {firstItemId !== item.id && (
                <Control onClick={this.goPreviousItem}>
                  <Icon src={arrow} alt="left arrow" />
                </Control>
              )}
            </ControlBox>
            <ControlBox>
              {lastItemId !== item.id && (
                <Control right onClick={this.goNextItem}>
                  <Icon src={arrow} alt="right arrow" />
                </Control>
              )}
            </ControlBox>
          </Controls>
        )}
      </InfoBoxHead>

      <InfoBoxBody align={this.displayTimelineInReverse() ? 'right' : 'left'}>
        <InfoBoxTitle>
          <FormattedMessage id={this.getTranslationTitleId(item.year)} />
        </InfoBoxTitle>
        <InfoBoxDescription>
          <FormattedMessage id={this.getTranslationDescriptionId(item.year)} />
        </InfoBoxDescription>
      </InfoBoxBody>
    </InfoBox>
  );

  renderModal = currentItem => (
    <Modal
      showModal={this.state.showModal}
      onRequestClose={this.handleCloseModal}
    >
      {!currentItem.video ? (
        <ImageLoader
          Component={FullSizeImage}
          key={`fullsize-${currentItem.id}`}
          id={currentItem.imageId}
          alt={currentItem.year.toString()}
        />
      ) : (
        <Player
          src={getVideoUrlById(currentItem.video.id)}
          id={currentItem.video.id}
          volume={70}
          controls
          autoPlay
        />
      )}
      {currentItem.imageId && (
        <Caption
          year={currentItem.year}
          align={this.displayTimelineInReverse() ? 'right' : 'left'}
          excerpt={
            <FormattedMessage
              id={this.getTranslationExcerptId(currentItem.year)}
            />
          }
        />
      )}
    </Modal>
  );

  renderTimeliny = () => {
    const { items } = this.state;
    const {
      intl: { locale: language }
    } = this.props;

    return (
      <Timeliny
        language={language}
        onChange={this.handleIndexChange}
        ref={instance => (this.child = instance)}
        displayReverse={this.displayTimelineInReverse()}
      >
        {items.map(item => (
          <div
            key={item.id}
            className={`timeliny-timeblock timeliny-item ${
              items[0].year === item.year ? 'active' : ''
            }`}
            data-year={item.year}
          >
            {item.year}
          </div>
        ))}
      </Timeliny>
    );
  };

  render() {
    const { currentItemIndex, items, direction } = this.state;

    if (items.length === 0) {
      return <div />;
    }

    const firstItem = this.displayTimelineInReverse()
      ? items[items.length - 1]
      : items[0];
    const lastItem = this.displayTimelineInReverse()
      ? items[0]
      : items[items.length - 1];
    const currentItem = items[currentItemIndex];
    const nextItem = this.displayTimelineInReverse()
      ? items[currentItemIndex - 1]
      : items[currentItemIndex + 1];

    return (
      items.length > 0 && (
        <LayoutSplitted>
          {this.renderRingWithIconButton(!!currentItem.imageId)}

          {this.renderInfoBox(currentItem, firstItem.id, lastItem.id)}

          {currentItem.id !== lastItem.id && (
            <RightSide
              className="right__side"
              style={{ opacity: isIE11() && 0.1 }}
            >
              <BlackBg />
              <TransitionGroup
                className={`carousel__slide slide__${direction}`}
              >
                <CSSTransition
                  key={`cssTransition-${nextItem.id}`}
                  classNames="carousel"
                  timeout={250}
                >
                  {isIE11() ? (
                    <ImageBackground
                      key={`bg-${currentItem.id}`}
                      style={{
                        backgroundImage: `url(${CloudinaryImage.getImageUrlById(
                          nextItem.imageId || nextItem.video.posterId
                        )})`
                      }}
                    />
                  ) : (
                    CloudinaryImage.getImageUrlById(
                      nextItem.imageId || nextItem.video.posterId
                    ) && (
                      <ImageLoader
                        Component={Image}
                        key={`right__side-${nextItem.id}`}
                        id={nextItem.imageId || nextItem.video.posterId}
                      />
                    )
                  )}
                </CSSTransition>
              </TransitionGroup>
            </RightSide>
          )}

          <LeftSide full={currentItem.id === lastItem.id}>
            <TransitionGroup className={`carousel__slide slide__${direction}`}>
              <CSSTransition
                key={`cssTransition-${currentItem.id}`}
                classNames="carousel"
                timeout={250}
              >
                {isIE11() ? (
                  <ImageBackground
                    key={`bg-${currentItem.id}`}
                    style={{
                      backgroundImage: `url(${CloudinaryImage.getImageUrlById(
                        currentItem.imageId || currentItem.video.posterId
                      )})`
                    }}
                  />
                ) : (
                  <ImageLoader
                    Component={Image}
                    key={currentItem.id}
                    id={currentItem.imageId || currentItem.video.posterId}
                    alt={currentItem.year.toString()}
                  />
                )}
              </CSSTransition>
            </TransitionGroup>
          </LeftSide>

          <TimelinyWrapper>{this.renderTimeliny()}</TimelinyWrapper>

          {this.renderModal(currentItem)}
        </LayoutSplitted>
      )
    );
  }
}

Timeline.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      year: PropTypes.number.isRequired,
      imageId: PropTypes.string,
      video: PropTypes.shape({
        id: PropTypes.string.isRequired,
        posterId: PropTypes.string.isRequired
      })
    })
  ).isRequired,
  intl: intlShape.isRequired
};

export default injectIntl(Timeline);
