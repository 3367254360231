import React, { Component } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Outer, Inner } from 'containers/Wrapper';
import { Row, Col } from 'react-styled-flexboxgrid';
import { animation } from 'styles/variables';
import Content from './Content';

import {
  IllustrationBox,
  Illustration,
  Wrapper,
  InnerWrapper,
  LeftCell,
  RightCell,
  Controls,
  Buttons,
  Button,
  Dots,
  Dot
} from './styled';

const slides = [
  'tripartism/keys-tripartism1',
  'tripartism/keys-tripartism2',
  'tripartism/keys-tripartism3',
  'tripartism/keys-tripartism4'
];

class SocialJusticeVis extends Component {
  static propTypes = {
    intl: intlShape.isRequired
  };

  state = {
    currentSlide: 0,
    slideMounted: true
  };

  toggleSlideMounted = () => {
    this.setState({
      slideMounted: !this.state.slideMounted
    });
  };

  changeSlide = direction => {
    const { currentSlide } = this.state;
    if (direction === 'next' && currentSlide < slides.length - 1) {
      this.showSlide(currentSlide + 1);
    }
    if (direction === 'previous' && currentSlide > 0) {
      this.showSlide(currentSlide - 1);
    }
    return null;
  };

  showSlide = slide => {
    this.toggleSlideMounted();
    return window.setTimeout(
      () =>
        this.setState({
          currentSlide: slide
        }),
      animation.inter
    );
  };

  handlePreviousButtonClick = () => {
    this.changeSlide('previous');
  };

  handleNextButtonClick = () => {
    this.changeSlide('next');
  };

  render() {
    const { currentSlide, slideMounted } = this.state;
    const {
      intl: { locale }
    } = this.props;
    return (
      <Wrapper dir={locale === 'ar' ? 'rtl' : 'ltr'}>
        <InnerWrapper>
          <Outer style={{ height: '100%' }}>
            <Inner>
              <Row reverse style={{ height: '100%' }}>
                <Col xs={12} sm={7} lg={7}>
                  <RightCell>
                    <IllustrationBox currentSlide={currentSlide}>
                      <Illustration />
                    </IllustrationBox>
                  </RightCell>
                </Col>
                <Col xs={12} sm={5} lg={5}>
                  <LeftCell>
                    <Content
                      toggleSlideMounted={this.toggleSlideMounted}
                      currentSlide={currentSlide}
                      slideMounted={slideMounted}
                    />
                    <Controls>
                      <Dots>
                        <button onClick={this.handlePreviousButtonClick} />
                        {slides.map((slide, i) => (
                          <Dot
                            key={slide}
                            thisdot={i}
                            currentSlide={currentSlide}
                          />
                        ))}
                        <button onClick={this.handleNextButtonClick} />
                      </Dots>
                      <Buttons>
                        <Button
                          disabled={currentSlide === 0}
                          currentSlide={currentSlide}
                          onClick={this.handlePreviousButtonClick}
                          locale={locale}
                        >
                          <FormattedMessage id="interface_previous" />
                        </Button>
                        <Button
                          slides={slides.length}
                          disabled={currentSlide === slides.length - 1}
                          currentSlide={currentSlide}
                          onClick={this.handleNextButtonClick}
                          locale={locale}
                        >
                          <FormattedMessage id="interface_next" />
                        </Button>
                      </Buttons>
                    </Controls>
                  </LeftCell>
                </Col>
              </Row>
            </Inner>
          </Outer>
        </InnerWrapper>
      </Wrapper>
    );
  }
}

export default injectIntl(SocialJusticeVis);
