import styled, { keyframes } from 'styled-components';

import media from 'styles/media';

const borderRadius = '2px';
const ringSize = '71px';

const pulsateSmall = keyframes`
  0% {
    transform: scale(1, 1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1.4, 1.4);
    opacity: 0;
  }
`;

const pulsateBig = keyframes`
  0% {
    transform: scale(1.4, 1.4);
    opacity: 0;
  }
  5% {
    opacity: 0.6;
  }
  100% {
    transform: scale(1.6, 1.6);
    opacity: 0;
  }
`;

const Wrapper = styled.div`
  width: ${ringSize};
  height: ${ringSize};
  border: ${borderRadius} solid white;
  border-radius: 50%;
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: center;
`;

export const RingSmall = styled.div`
  width: ${ringSize};
  height: ${ringSize};
  border: ${borderRadius} solid white;
  border-radius: 50%;
  position: absolute;
  top: -${borderRadius};
  left: -${borderRadius};
  animation: ${pulsateSmall} 1s ease-out;
  animation-iteration-count: infinite;

  ${media.xs`
    animation: none;
  `};
`;

export const RingBig = styled.div`
  width: ${ringSize};
  height: ${ringSize};
  border: ${borderRadius} solid white;
  border-radius: 50%;
  position: absolute;
  top: -${borderRadius};
  left: -${borderRadius};
  animation: ${pulsateBig} 1s ease-out;
  animation-iteration-count: infinite;

  ${media.xs`
    animation: none;
  `};
`;

export default Wrapper;
