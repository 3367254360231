import React from 'react';
import InfoCollage from 'modules/InfoCollage';

const collageLayers = [
  'tripartism/littlegirl-1',
  'tripartism/littlegirl-2',
  'tripartism/littlegirl-3'
];

const Block = () => (
  <InfoCollage
    collage={{
      layers: collageLayers,
      caption: {
        message: 'tripartism_imageGirlcottonCaption',
        colProps: {
          xs: 12,
          sm: 7,
          smOffset: 2,
          md: 7,
          mdOffset: 3,
          lg: 6,
          lgOffset: 2
        },
        voffset: {
          md: '4rem',
          lg: '4rem',
          xs: '0'
        }
      },
      colProps: {
        xs: 12,
        smOffset: 0,
        sm: 12,
        mdOffset: -1,
        md: 8
      }
    }}
    infoBoxes={[
      {
        message: 'tripartism_infoLifeIndustry',
        colProps: {
          xs: 12,
          smOffset: 1,
          sm: 9,
          mdOffset: 6,
          md: 5,
          lgOffset: 6,
          lg: 6
        }
      }
    ]}
    voffset={{
      sm: '25rem 0 0',
      xs: '0'
    }}
  />
);

export default Block;
