import styled from 'styled-components';
import media from 'styles/media';

export const Wrapper = styled.section`
  position: relative;
  min-height: 45rem;

  ${media.md`
    min-height: 38rem;
  `}

  ${media.sm`
    min-height: 40rem;
  `}

  ${media.xs`
    min-height: auto;
  `}
`;

export const CollageWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;

  ${media.portrait`
    left: -3rem;
    opacity: 0.6;
  `};

  ${media.xs`
    position: relative;
  `}
`;

export default Wrapper;
