import styled, { keyframes } from 'styled-components';

import media from 'styles/media';
import { silverChalice } from 'styles/variables';
import { Icon } from 'modules/Timeline/styled';
import './timeliny.min.css';

const bounceLeft = keyframes`
  0% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(5px);
  }
`;

const bounceRight = keyframes`
  0% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-5px);
  }
`;

export default styled.div`
  position: relative;
  padding-bottom: 40px;

  #timeliny .timeliny-timeline {
    transition: left 0.25s linear;
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;

    &.moving {
      transition: none !important;
    }
  }

  ${media.xs`
    padding-bottom: 45px;
  `};
`;

export const TimelinyWrapper = styled.div`
  position: absolute;
  min-height: 100px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 2;

  ${media.xs`
    position: relative;
    margin-top: 0;
    flex-direction: column;
  `};
`;

export const TimelinyDragWrapper = styled.div`
  position: absolute;
  width: 100%;
  opacity: 0;
  height: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
  text-align: center;
  bottom: 0;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  transition: opacity 1300ms ease-out;
  transition-delay: 1s;
`;

export const TimelinyDrag = styled.div`
  width: 75px;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  pointer-events: none;
  
  *::selection {
    user-select: none;
  }

  ${media.xs`
    width: 40px;
    padding: 15px 0 25px 0;
  `};
`;

export const TimelinyDragItem = styled.div`
  min-width: 14px;
  animation: ${props => (props.right ? bounceRight : bounceLeft)} 1s ease-in-out;
  animation-iteration-count: infinite;

  ${media.xs`
    animation: none;
  `};
`;

export const TimelinyDragHand = styled(Icon)`
  ${media.xs`
    display: none;
  `};
`;

export const TimelinyDragSwipe = styled.div`
  display: none;
  color: ${silverChalice};
  justify-content: center;
  font-size: 12px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;

  ${media.xs`
    display: flex;
  `};
`;
