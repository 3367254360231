import styled from 'styled-components';
import { white, serif } from 'styles/variables';
import { P } from 'styles/typography';
import media from 'styles/media';

export const SlideBody = styled(P)`
  color: ${white};
  font-family: ${serif};
  font-size: 1.625rem;
  text-align: center;
  margin: 0 0 3rem;

  ${media.md`
    font-size: 1.375rem;
    line-height: 2rem;
  `};

  ${media.xs`
    text-align: left;
    font-size: 1.375rem;
    line-height: 2rem;
  `};
`;

export const SlideCaption = styled(P)`
  color: ${white};
  text-align: center;

  ${media.xs`
    text-align: left;
  `};
`;
