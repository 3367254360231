import React from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import { Inner, Outer } from 'containers/Wrapper';
import Quote from 'components/Quote';
import Collage from 'components/Collage';
import { Wrapper, CollageWrapper } from './styled';

const collageLayers = [
  'tripartism/tripartism-roosevelt-1',
  'tripartism/tripartism-roosevelt-2',
  'tripartism/tripartism-roosevelt-3'
];

const Block = () => (
  <Wrapper>
    <Outer>
      <Inner>
        <section>
          <Row>
            <Col
              xs={12}
              smOffset={-2}
              sm={5}
              mdOffset={-1}
              md={5}
              lgOffset={-2}
              lg={6}
            >
              <CollageWrapper>
                <Collage
                  layers={collageLayers}
                  imageStyle={{
                    width: { lg: '800px', md: '700px', sm: '480px', xs: '100%' }
                  }}
                />
              </CollageWrapper>
            </Col>
            <Col
              xs={12}
              smOffset={5}
              sm={6}
              mdOffset={2}
              md={5}
              lgOffset={2}
              lg={5}
            >
              <Quote
                quoteID="tripartism_quoteWildDreamBody"
                captionID="tripartism_quoteWildDreamCaption"
                soundID="tripartism/roosevelt-quote"
              />
            </Col>
          </Row>
        </section>
      </Inner>
    </Outer>
  </Wrapper>
);

export default Block;
