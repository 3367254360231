import styled, { css } from 'styled-components';
import Callout from 'components/Callout';
import { Outer } from 'containers/Wrapper';
import { serif } from 'styles/variables';
import media from 'styles/media';
import badge from 'assets/images/world-war-badge.png';

export const OuterWrapper = styled.section`
  position: relative;
  height: 75rem;
  margin: 0 0 1rem 0;

  ${media.md`
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 78rem;
  `}

  ${media.sm`
    height: 70rem;
    margin: 0 0 2rem;
  `}

  ${media.xs`
    height: auto;
  `}
`;

export const BannerWrapper = styled(Outer)`
  padding-left: 0 !important;
  position: relative;
  height: 55rem;

  ${media.sm`
    height: 45rem;
  `}

  ${media.xs`
    height: 25rem;
    padding: 0;
    margin: 0 0 2rem 0;

    .parallax-banner-layer-0 {
      background-position: top 0 right 10% !important;
      top: 0 !important;
      bottom: 0 !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;
    }
  `};
`;

export const CalloutWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const InnerWrapper = styled.div`
  position: relative;
  display: flex;
  width: inherit;
  height: inherit;
  justify-content: center;
  align-items: center;
`;

const decoratedText = css`
  position: absolute;
  top: 22%;
  color: white;
  height: 50px;
  width: 50px;
  font-family: ${serif};
  font-style: italic;
  font-size: 2.5rem;
  ${media.xs`
    display: none;
  `};
`;

export const StyledCallout = styled(Callout)`
  position: relative;
  font-size:
`;

export const Stamp = styled.div`
  position: relative;
  &:after {
    content: '';
    background: url(${badge}) center no-repeat;
    background-size: cover;
    position: absolute;
    top: -20px;
    right: 0;
    width: 90px;
    height: 85px;

    ${media.xs`
      width: 45px;
      height: 45px;
      top: -5px;
      right: 20px;
    `};
  }
`;

export const InfoBoxWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;

  ${
    media.md`
      position: relative;
    `
  }
`