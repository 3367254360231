import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'components/Fade';
import { animation } from 'styles/variables';

const Slider = ({
  slideMounted,
  toggleSlideMounted,
  currentSlide,
  children
}) => (
  <Fade
    inProp={slideMounted}
    onExiting={toggleSlideMounted}
    timeout={animation.medium}
    outerStyle={{ height: '70%'}}
  >
    {React.Children.toArray(children)[currentSlide]}
  </Fade>
);

Slider.propTypes = {
  slideMounted: PropTypes.bool,
  toggleSlideMounted: PropTypes.func,
  currentSlide: PropTypes.number,
  children: PropTypes.node
};

Slider.defaultProps = {
  slideMounted: true,
  currentSlide: 0,
  toggleSlideMounted: () => {},
  children: null
};

export default Slider;
