import styled from 'styled-components';

import media from 'styles/media';

import { alto, white } from 'styles/variables';

export const SeeThisPhotoVideo = styled.a`
  position: absolute;
  top: 30%;
  left: 13%;
  z-index: 1;
  cursor: pointer;

  ${media.md`
    left: 10%;
  `};

  ${media.sm`
    left: 20px;
  `};

  ${media.xs`
    top: 160px;
    left: 0;
    right: 0;
  `};
`;

export const SeeThisPhotoVideoText = styled.div`
  color: ${white};
  margin-top: 40px;
  display: block;
  font-size: 0.75rem;
  text-transform: uppercase;

  ${media.xs`
    display: none;
  `};
`;

export const Icon = styled.img`
  display: block;
  align-self: center;
`;

export const Line = styled.div`
  flex: 1;
  height: 1px;
  background-color: ${alto};
  margin: 0 20px;
`;

export const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
`;
