import React from 'react';
import { withIntl } from 'i18n';
import Template from 'modules/ChapterTemplate';
import MovingImageBlock from 'modules/MovingImage';
import Block1Parallax from 'blocks/tripartism/Block-1-Parallax';
import Block2Quote from 'blocks/tripartism/Block-2-Quote';
import Block3Parallax from 'blocks/tripartism/Block-3-Parallax';
import Block4Quotes from 'blocks/tripartism/Block-4-Quotes';
import Block5Callout from 'blocks/tripartism/Block-5-Callout';
import Block7Collage from 'blocks/tripartism/Block-7-Collage';
import Block9Callout from 'blocks/tripartism/Block-9-Callout';
import Block11Collage from 'blocks/tripartism/Block-11-Collage';
import Block12Callout from 'blocks/tripartism/Block-12-Callout';
import Block13Quote from 'blocks/tripartism/Block-13-Quote';
import Block14Callout from 'blocks/tripartism/Block-14-Callout';
import Timeline from 'modules/Timeline';
import SocialJusticeVis from 'modules/SocialJustice';
import Conclusion from 'modules/Conclusion';
import { theme, timelineItems } from 'config/tripartism.json';

const Tripartism = () => (
  <Template theme={theme}>
    <main>
      <Block1Parallax />
      <Block2Quote />
      <Block3Parallax />
      <Block4Quotes />
      <Block5Callout />
      <MovingImageBlock
        videoID="tripartism/videoWwiAmbiance"
        soundID="tripartism/battlefield-sounds"
      />
      <Block7Collage />
      <MovingImageBlock
        videoID="tripartism/videoIndustry1900S"
        soundID="tripartism/factory-sounds"
      />
      <Block9Callout />
      <MovingImageBlock
        videoID="tripartism/videoDemonstration1919"
        soundID="tripartism/ambient-sound-crowd"
      />
      <Block11Collage />
      <Block12Callout />
      <MovingImageBlock
        videoID="tripartism/videoIloInauguration"
        soundID="tripartism/ambient-sound-ilo-opening"
      />
      <Block13Quote />
      <Block14Callout />
      <Timeline data={timelineItems} />
      <SocialJusticeVis />
      <Conclusion
        mainTextId="tripartism_calloutDistinctHeading"
        subTextId="tripartism_calloutDistinctBody"
      />
    </main>
  </Template>
);

export default withIntl(Tripartism);
