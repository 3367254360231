import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ScrollBar from 'components/ScrollBar';
import getContentBySubstring from 'utils/getContentBySubstring';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import Slider from './Slider';

import { Title, Content, Heading, BodyText, BodyTextContainer } from './styled';

class SocialJusticeVisContent extends Component {
  state = {
    scrollBarHeight: 300
  };

  componentDidUpdate(prevProps) {
    if (prevProps.currentSlide !== this.props.currentSlide) {
      this.setScrollBarHeight();
    }
  }

  setScrollBarHeight = () => {
    if (this.textContainerRef.current !== null) {
      const { height } = this.textContainerRef.current.getBoundingClientRect();
      this.setState({ scrollBarHeight: height });
    }
  };

  textContainerRef = React.createRef();

  render() {
    const {
      intl: { locale, messages },
      toggleSlideMounted,
      slideMounted,
      currentSlide
    } = this.props;
    const { scrollBarHeight } = this.state;
    return (
      <Slider
        toggleSlideMounted={toggleSlideMounted}
        currentSlide={currentSlide}
        slideMounted={slideMounted}
        style={{ flex: '1 1 auto' }}
      >
        <Content>
          <Title locale={locale}>
            <FormattedHTMLMessage id="tripartism_visSocJustTitle" />
          </Title>
        </Content>
        {getContentBySubstring(messages, 'tripartism_visSocJustBody').map(
          (slide, i) => (
            <Content key={slide}>
              <Heading>
                <FormattedHTMLMessage
                  id={`tripartism_visSocJustHeading${i + 1}`}
                />
              </Heading>
              <BodyTextContainer ref={this.textContainerRef}>
                <ScrollBar height={scrollBarHeight}>
                  <BodyText locale={locale}>
                    <FormattedHTMLMessage id={slide} />
                  </BodyText>
                </ScrollBar>
              </BodyTextContainer>
            </Content>
          )
        )}
      </Slider>
    );
  }
}

const { shape, func, number, bool } = PropTypes;
SocialJusticeVisContent.propTypes = {
  slideMounted: bool.isRequired,
  toggleSlideMounted: func.isRequired,
  currentSlide: number.isRequired,
  intl: shape({
    messages: shape({}).isRequired
  }).isRequired
};

export default injectIntl(SocialJusticeVisContent);
