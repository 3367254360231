import React from 'react';
import PropTypes from 'prop-types';
import { Outer, Inner } from 'containers/Wrapper';
import ParallaxBanner from 'containers/Parallax/ParallaxBanner';
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Row, Col } from 'react-styled-flexboxgrid';
import CloudinaryImage from 'components/Image';
import InfoBox from 'components/InfoBox';

import {
  OuterWrapper,
  BannerWrapper,
  CalloutWrapper,
  InnerWrapper,
  StyledCallout,
  BottomTear,
  InfoBoxWrapper,
  Overlay
} from './styled';

const Block = ({ intl: { locale, messages } }) => (
  <OuterWrapper>
    <BannerWrapper>
      <ParallaxBanner
        layers={[
          {
            image: CloudinaryImage.getImageUrlById('tripartism/versailles'),
            amount: 0.1,
            slowerScrollRate: false
          }
        ]}
        style={{
          zIndex: 0,
          height: '100%',
          width: '100%'
        }}
      >
        <CalloutWrapper>
          <Overlay />
          <InnerWrapper>
            <Row center="xs" style={{flex: 1}}>
              <Col xs={10} sm={8}>
                <StyledCallout
                  size="medium"
                  className="callout-with-fringes"
                  locale={locale}
                  text={{
                    article: messages.interface_masculineArticle,
                    of: messages.interface_of
                  }}
                  color="white"
                >
                  <FormattedMessage id="tripartism_calloutVersailles" />
                </StyledCallout>
              </Col>
            </Row>
          </InnerWrapper>
        </CalloutWrapper>
      </ParallaxBanner>
      <BottomTear />
    </BannerWrapper>
    <InfoBoxWrapper>
      <Outer>
        <Inner>
          <Row>
            <Col xs={12} smOffset={2} sm={8} mdOffset={5} md={6} >
              <InfoBox>
                <FormattedHTMLMessage id="tripartism_infoVersailles" />
              </InfoBox>
            </Col>
          </Row>
        </Inner>
      </Outer>
    </InfoBoxWrapper>
  </OuterWrapper>
);

Block.propTypes = {
  intl: PropTypes.shape({
    locale: PropTypes.string,
    messages: PropTypes.shape({})
  })
};

Block.defaultProps = {
  intl: {
    locale: 'en',
    messages: {}
  }
};

export default injectIntl(Block);
