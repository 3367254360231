import React from 'react';
import PropTypes from 'prop-types';

import ImageIcon from 'assets/images/timeline/image-icon.svg';
import VideoIcon from 'assets/images/timeline/video-icon.svg';
import { Icon } from 'modules/Timeline/styled';
import Wrapper, { RingSmall, RingBig } from './styled';

const getSmallSize = (step) => 1 + (step / 100);
const getBigSize = (step) => 1 + 2 * (step / 100);

const PulsateCircle = ({ isPhoto, step }) => (
  <Wrapper>
    <Icon src={isPhoto ? ImageIcon : VideoIcon} alt="image" />
    <RingSmall size={getSmallSize(step)} />
    <RingBig size={getBigSize(step)} />
  </Wrapper>
);

PulsateCircle.defaultProps = {
  step: 2,
  isPhoto: true,
};

PulsateCircle.propTypes = {
  step: PropTypes.number,
  isPhoto: PropTypes.bool,
};

export default PulsateCircle;