import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper } from './styled';

const LayoutSplitted = ({ children }) => (
  <Wrapper>
    {children}
  </Wrapper>
);

LayoutSplitted.defaultProps = {
  children: '',
};

LayoutSplitted.propTypes = {
  children: PropTypes.node,
};

export default LayoutSplitted;

