import styled from 'styled-components';

import { Paragraph } from 'modules/Timeline/styled';
import { serif, white } from 'styles/variables';
import media from 'styles/media';

export default styled.div`
  background-color: ${white};
  padding: 3.75rem 6.26rem;
  box-sizing: border-box;
  position: absolute;
  top: 6.26rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 2;
  width: 640px;

  ${media.sm`
    padding: 3.75rem 3rem;
    max-width: 500px;
  `};
  
  ${media.xs`
    position: relative;
    top: initial;
    max-width: 100%;
    order: 2;
    padding: 20px 15px 20px 20px;
  `};
  
   @media screen and (max-height: 850px) {
    max-width: 600px;
    width: 100%;
   }
`;

export const InfoBoxHead = styled.div`
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
`;

export const InfoBoxBody = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 3.1rem;
  text-align: ${props => props.align || 'left'};
`;

export const InfoBoxTitle = styled(Paragraph)`
  font-size: 1.8rem;
  font-weight: 400;
  word-break: break-word;
  font-family: ${serif};
  line-height: 1.3;
  margin: 0 0 2.5rem 0;
  letter-spacing: -0.5px;

  ${media.xs`
    line-height: 1;
  `};
`;

export const InfoBoxDescription = styled(Paragraph)`
  font-size: 0.95rem;
  font-weight: 400;
`;
