import styled from 'styled-components';
import media from 'styles/media';

export const Wrapper = styled.section`
  position: relative;
  height: 50rem;

  .parallax-outer {
    position: absolute;
    left: 0;
    top: 20%;
  }

  ${media.xs`
    height: auto;

    .parallax-outer {
      position: relative;
      top: 0;
    }
  `};
`;

export const FigureWrapper = styled.div`
  width: auto;

  ${media.portrait`
    width: 60vw;
  `}
`

export default Wrapper;
