import styled from 'styled-components';
import { white } from 'styles/variables';
import media from 'styles/media';

export const Wrapper = styled.section`
  height: 50em;
  width: 100%;
  background-color: ${white};
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  ${media.sm`
    height: 38rem;
  `};

  ${media.xs`
    height: auto;
    padding: 5rem 0;
  `}

  img {
    width: 100%;
  }
`;

export default Wrapper;
