/* eslint-disable react/no-array-index-key, react/no-did-mount-set-state */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Fade from 'components/Fade';
import Swipeable from 'react-swipeable';
import { animation } from 'styles/variables';
import { Wrapper, Arrow, Dots, Dot } from './styled';

class Slider extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired
  };

  state = {
    currentSlide: 0,
    numberSlides: 0,
    slideMounted: true
  };

  componentDidMount() {
    const children = React.Children.toArray(this.props.children);
    this.setState({
      numberSlides: children.length
    });
  }

  showSlide = slide => {
    this.toggleSlideMounted();
    window.setTimeout(
      () =>
        this.setState({
          currentSlide: slide
        }),
      animation.inter
    );
  };

  showNextSlide = () => {
    const { currentSlide, numberSlides } = this.state;
    if (currentSlide < numberSlides - 1) {
      this.showSlide(currentSlide + 1);
    }
  };

  showPrevSlide = () => {
    const { currentSlide } = this.state;
    if (currentSlide > 0) {
      this.showSlide(currentSlide - 1);
    }
  };

  toggleSlideMounted = () => {
    this.setState({
      slideMounted: !this.state.slideMounted
    });
  };

  renderSlide = () => {
    const { currentSlide } = this.state;
    const children = React.Children.toArray(this.props.children);
    return children[currentSlide];
  };

  renderDots = () => {
    const { currentSlide } = this.state;
    const slides = React.Children.toArray(this.props.children);
    if (slides.length > 1) {
      return (
        <Dots>
          {slides.map((slide, i) => (
            <Dot
              key={i}
              onClick={() => this.showSlide(i)}
              isSelected={currentSlide === i}
            />
          ))}
        </Dots>
      );
    }
    return null;
  };

  render() {
    const { currentSlide, numberSlides, slideMounted } = this.state;
    return (
      <Swipeable
        onSwipingLeft={this.showNextSlide}
        onSwipingRight={this.showPrevSlide}
      >
        <Wrapper>
          <Arrow
            left
            onClick={this.showPrevSlide}
            disabled={currentSlide === 0}
          />
          <Fade inProp={slideMounted} onExiting={this.toggleSlideMounted}>
            {this.renderSlide()}
          </Fade>
          <Arrow
            right
            onClick={this.showNextSlide}
            disabled={currentSlide === numberSlides - 1}
          />
          {this.renderDots()}
        </Wrapper>
      </Swipeable>
    );
  }
}

export default Slider;
