/* eslint-disable no-underscore-dangle */
/* eslint-disable no-return-assign */
/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import React from 'react';
import PropTypes from 'prop-types';

import {Icon} from "modules/Timeline/styled";
import dragArrowLeftIcon from "assets/images/timeline/drag-arrow-left.svg";
import dragHandIcon from "assets/images/timeline/hand.svg";
import dragArrowRightIcon from "assets/images/timeline/drag-arrow-right.svg";
import Wrapper, {
  TimelinyDrag,
  TimelinyDragHand,
  TimelinyDragItem,
  TimelinyDragSwipe,
  TimelinyDragWrapper
} from "./styled";

const windowGlobal = typeof window !== 'undefined' && window;

if (windowGlobal && window.Element && !Element.prototype.closest) {
  Element.prototype.closest =
    function(s) {
      const matches = (this.document || this.ownerDocument).querySelectorAll(s);
      let i;
      let el = this;
      do {
        i = matches.length;
        while (--i >= 0 && matches.item(i) !== el) { };
      } while ((i < 0) && (el = el.parentElement));
      return el;
    };
}

class Timeliny extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentIndex: 0,
    };

    this.onClick = this.onClick.bind(this);
    this.onResize = this.onResize.bind(this);
  }

  componentDidMount() {
    this.isUnmount = false;
    this.clickFlag = false;
    this._init();
  }

  componentWillUnmount() {
    this.isUnmount = true;
    this.destroy();
  }

  /* CORE */
  _init() {
    this._el = this.el;

    this._children = this._el.querySelector(`.${this.props.options.className}-timeline`).children;
    this._el.querySelector('.timeliny-timeline').style.transition = 'all 0.25s linear';
    this.hook('onInit');
    //
    this._createWrapper();
    this._createDots();
    this._clickBehavior();
    this._setupItemsDistance();
    this._createVerticalLine();
    this._updateTimelinePos('init');
    this._resizeBehavior();
    this._dragableTimeline();
    this._loaded();
  }

  _loaded() {
    const { options } = this.props;
    const currYear = this._el.querySelector(`.${options.className}-timeblock.active`).getAttribute('data-year');
    this.hook('afterLoad', [currYear]);
    this.timelinyDrag.style.opacity = 1;

    this.el.classList.add('loaded');
  }

  _createWrapper () {
    const { options } = this.props;
    this.el.classList.add(options.className);
  }

  _createDots() {
    const { options } = this.props;

    Array.prototype.forEach.call(this._children, function(element){
      const text = element.innerHTML;

      const year = element.getAttribute('data-year');

      /* eslint-disable-next-line prefer-template */
      const dotHtml = '<a href="#' + year + '" class="' + options.className + '-dot" data-year="' + year + '" data-text="' + text + '"></a><span class="' + options.className + '-circle"></span>';

      if (element.classList) {
        element.classList.add(`${options.className}-timeblock`);
      }
      else {
        element.className += ` ${options.className}-timeblock`;
      }
      element.innerHTML = dotHtml;
    });
  }

  offsetRight = (element) => (
    document.body.offsetWidth - (element.getBoundingClientRect().left)
  );

  _updateTimelinePos(callEvent) {
    const { options, displayReverse } = this.props;

    if (!this._el.querySelector(`.${options.className}-timeblock.active`)) {
      return false;
    }
    const linePos = this._el.querySelector(`.${options.className}-vertical-line`).offsetLeft;
    const activeDotPos = this._el.querySelector(`.${options.className}-timeblock.active`).offsetLeft;
    const dotRadius = parseInt(getComputedStyle(this._el.querySelector(`.${options.className}-timeblock.active .${options.className}-dot`)).width, 10) / 2;

    const diff = activeDotPos - linePos;
    let left;

    if (displayReverse) {
      if (diff > 0) {
        left = `${(Math.abs(diff) + dotRadius + 1)}`;
      } else {
        left = `-${(Math.abs(diff) - dotRadius - 1)}`;
      }
    } else {
      if (diff > 0) {
        left = `-${(Math.abs(diff) + dotRadius + 1)}`;
      } else {
        left = (Math.abs(diff) - dotRadius - 1);
      }
    }

    if (displayReverse) {
      if (left >= 0) {
        this._el.querySelector('.timeliny-timeline').style.transform =  `translate3d(-${left}px, 0, 0)`;
      } else {
        this._el.querySelector('.timeliny-timeline').style.transform =  `translate3d(${Math.abs(left)}px, 0, 0)`;
      }

    } else {
      this._el.querySelector('.timeliny-timeline').style.transform =  `translate3d(${left}px, 0, 0)`;
    }

    if (typeof callEvent !== 'undefined') {
      if (callEvent === 'click') {
        const currYear = this._el.querySelector(`.${options.className}-timeblock.active`).getAttribute('data-year');

        const item = this._el.querySelector(`.timeliny-item[data-year="${currYear}"]`);
        const index = this.indexInParent(item);

        if (!this.isUnmount) {
          this.setState({ currentIndex: index });
          this.props.onChange(index);
        }

      }
    }
  }

  _clickBehavior() {
    this.addListenerMulti(document, 'click touchstart', this.onClick);
  }

  onClick(event) {
    const { options } = this.props;
    const self = this;

    if ( event.target.classList.contains(`${options.className}-dot`) ) {
      event.preventDefault();
      if (!this.clickFlag) {
        this.clickFlag = true;
        setTimeout(() => this.clickFlag = false, 100);
        const element = event.target;


        Array.prototype.forEach.call(self._children, (child) => {
          child.classList.remove('active');
        });

        const closest = element.closest(`.${options.className}-timeblock`);
        closest.className += ' active';
        self._updateTimelinePos('click');
      }
    }

    return false;
  }

  _setupItemsDistance() {
    const { options, displayReverse } = this.props;
    const distance = options.distance || 30;
    Array.prototype.forEach.call(this._children, (child) => {
      const currYear = child.getAttribute('data-year');
      let nextYear = 0;
      if (child.nextElementSibling) {
        nextYear = child.nextElementSibling.getAttribute('data-year');
      }

      const margin = (parseInt(nextYear, 10) - parseInt(currYear, 10)) * distance;

      if (displayReverse){
        child.style.marginLeft = `${margin}px`;
        child.style.marginRight = `initial`;
      } else {
        child.style.marginRight = `${margin}px`;
        child.style.marginLeft = `initial`;
      }

    });
  }

  debounce(callback, delay) {
    let timer;

    return function(){
      const args = arguments;
      const context = this;
      clearTimeout(timer);
      timer = setTimeout(() => {
        callback.apply(context, args);
      }, delay)
    }
  }

  _resizeBehavior() {
    windowGlobal.addEventListener('resize', this.onResize);
  }

  onResize() {
    const self = this;
    this.debounce(() => {
      self._updateTimelinePos('resize');
    }, 100)();
  }

  _createVerticalLine() {
    const { options } = this.props;
    const vertLine = document.createElement('div');
    vertLine.classList.add(`${options.className}-vertical-line`);
    vertLine.style.opacity = options.disableVerticalLine ? 0 : 1;
    vertLine.style.position = options.disableVerticalLine ? 'absolute' : '';
    vertLine.style.zIndex = options.disableVerticalLine ? '-999999' : '';
    this._el.appendChild(vertLine);
  }

  _dragableTimeline() {
    const self = this;
    const { options, displayReverse } = this.props;
    let isMousedown = false;
    let selected = null;
    let x_pos = 0;
    let x_elem = 0;
    let lastX;
    let directionRight = true;

    // Will be called when user starts dragging an element
    function _drag_init(elem, e) {
      if (e.targetTouches) {
        const touch = e.targetTouches[0];
        if (displayReverse) {
          x_pos = document.body.offsetWidth - touch.pageX;
        } else {
          x_pos = touch.pageX;
        }

        directionRight = undefined;
      }
      selected = elem;
      if (displayReverse) {
        x_elem = x_pos - (document.body.offsetWidth - selected.getBoundingClientRect().right + document.body.scrollLeft);
      } else {
        x_elem = x_pos - (selected.getBoundingClientRect().left + document.body.scrollLeft);
      }
    }

    // Will be called when user dragging an element
    function _move_elem(e) {
      if (isMousedown) {
        self.timelineEl.classList.add('moving');
      }

      /* if touch event / mobile */
      /* TODO: arabic mobile */
      if (e.targetTouches) {
        const touch = e.targetTouches[0];

        if (isMousedown) {
          if(displayReverse) {
            x_pos = document.body.offsetWidth - touch.pageX;
            selected.style.transform = `translate3d(-${(x_pos - x_elem)}px, 0, 0)`;
          }
          else {
            selected.style.transform = `translate3d(${(touch.pageX - x_elem)}px, 0, 0)`;
          }
        }

        const currentX = e.touches[0].clientX;
        if(currentX > lastX){
          directionRight = !!displayReverse;
        }else if(currentX < lastX){
          directionRight = !displayReverse;
        }
        lastX = currentX;
      }
      else {
        if (displayReverse) {
          x_pos = document.all ? document.body.offsetWidth - window.event.clientX : document.body.offsetWidth - e.pageX;
        } else {
          x_pos = document.all ? window.event.clientX : e.pageX;
        }

        if (selected !== null && isMousedown) {
          if (displayReverse) {
            if (x_pos - x_elem >= 0) {
              selected.style.transform = `translate3d(${-(x_pos - x_elem)}px,0,0)`;
            } else {
              selected.style.transform = `translate3d(${Math.abs(x_pos - x_elem)}px,0,0)`;
            }

          } else {
            selected.style.transform = `translate3d(${(x_pos - x_elem)}px, 0, 0)`;
          }
        }

        let currentPageX;
        if (displayReverse) {
          currentPageX = document.body.offsetWidth - e.pageX;
        } else {
          currentPageX = e.pageX;
        }

        if (displayReverse) {
          if(currentPageX < lastX){
            directionRight = false;
          } else if(currentPageX > lastX){
            directionRight = true;
          }
        } else {
          if(currentPageX > lastX){
            directionRight = false;
          } else if(currentPageX < lastX){
            directionRight = true;
          }
        }


        lastX = currentPageX;

        if (isMousedown) {
          const closestDotYearIndex = self.getClosestDotYearIndex(e, directionRight);
          const elements = self._el.querySelectorAll(`.${options.className}-dot`);
          Array.prototype.forEach.call(elements, (child) => {
            child.classList.remove('highlight');
          });
          elements[closestDotYearIndex].classList.add('highlight');
        }

      }
    }

    // Destroy the object when we are done
    function _stop_move(e) {
      self.timelineEl.classList.remove('moving');
      if (selected) {
        const closestDotYearIndex = self.getClosestDotYearIndex(e, directionRight);
        const closestElement = self._el.querySelectorAll(`.${options.className}-dot`)[closestDotYearIndex];
        closestElement.classList.remove('highlight');

        closestElement.click();
        selected = null;
      }
    }

    // Bind the functions...
    self.addListenerMulti(document.querySelector(options.draggedElement), 'touchstart mousedown', (e) => {
      isMousedown = true;
      _drag_init(self.timelineEl, e);
    });

    self.addListenerMulti(document.querySelector(options.draggedElement), 'touchmove mousemove', (event) => {
      _move_elem(event);
    });

    self.addListenerMulti(document.querySelector(options.draggedElement), 'touchend mouseup', (e) => {
      isMousedown = false;
      _stop_move(e);
    });

    self.addListenerMulti(document.querySelector(options.draggedElement), 'mouseout', (e) => {
      isMousedown = false;
      _stop_move(e);
    });
  }

  /* Helpers */
  closestByClass (el, className) {
    const classes = el.classList.split(' ');
    // Traverse the DOM up with a while loop
    while (!classes.includes(className)) {
      // Increment the loop to the parent node
      el = el.parentNode;
      if (!el) {
        return null;
      }
    }
    // At this point, the while loop has stopped and `el` represents the element that has
    // the class you specified in the second parameter of the function `clazz`

    // Then return the matched element
    return el;
  }

  indexInParent(node) {
    const children = node.parentNode.childNodes;
    let num = 0;
    for (let i=0; i<children.length; i++) {
      if (children[i] === node) {
        return num;
      }
      if (children[i].nodeType === 1) {
        num++;
      }
    }
    return -1;
  }

  addListenerMulti(element, eventNames, listener, passive) {
    const events = eventNames.split(' ');
    for (let i=0, iLen=events.length; i<iLen; i++) {
      if (passive) {
        element.addEventListener(events[i], listener, { passive: false });
      } else {
        element.addEventListener(events[i], listener, false);
      }
    }
  }

  closestValue(v, bandwidthSteps, directionRight, distance, activeIndex) {
    if (typeof activeIndex !== 'undefined') {
      const next = !bandwidthSteps[activeIndex + 1] ? activeIndex : activeIndex + 1;
      const prev = !bandwidthSteps[activeIndex - 1] ? activeIndex : activeIndex - 1;

      if (typeof directionRight === 'undefined') return activeIndex;

      return directionRight ? next : prev;
    }
    let value;
    bandwidthSteps.some(function (a, index, array) {
      let nextEl = null;

      if (!array[index + 1]) {
        if (v >= a.currDotPos) {
          value = array[array.length - 1].index;
        } else {
          value = array[0].index;
        }

        return true;
      }
      nextEl = array[index + 1];

      const delta = Math.abs(v - a.currDotPos);
      const diff = nextEl.currDotPos - a.currDotPos;
      const half = diff / 2;
      const velocity = directionRight ? half - half * distance : half + half * distance;
      const isBetween = a.currDotPos < v && v < nextEl.currDotPos;

      if (isBetween) {
        if (delta >= velocity) {
          value = a.index + 1;
        } else {
          value = a.index;
        }

        return true;
      }
    });

    return value;
  }

  getClosestDotYearIndex(e, directionRight) {
    // active the closest elem
    const { options, displayReverse } = this.props;
    const distance = 0;
    const linePos = this._el.querySelector(`.${options.className}-vertical-line`).getBoundingClientRect().left + document.body.scrollLeft;
    const allDotsPos = [];

    Array.prototype.forEach.call(this._children[0].parentElement.querySelectorAll(`.${options.className}-dot`), (child, index) => {
      let currDotPos;
      if (displayReverse) {
        currDotPos = this.offsetRight(child);
      } else {
        currDotPos = child.getBoundingClientRect().left + document.body.scrollLeft;
      }
      allDotsPos.push({ currDotPos, index });
    });

    if (e.targetTouches) {
      const activeIndex = this.indexInParent(this._el.querySelector(`.${options.className}-timeblock.active`));
      return this.closestValue(linePos, allDotsPos, directionRight, distance, activeIndex);
    }

    return this.closestValue(linePos, allDotsPos, directionRight, distance);
  }

  goToYear(year) {
    const { options } = this.props;
    const selector = this._el.querySelectorAll(`.${options.className}-timeblock[data-year="${year}"]:not(.inactive) .${options.className}-dot`)[0];

    if (selector) {
      selector.click();
    }
  }

  hook(hookName, args) {
    const { options } = this.props;
    if (options[hookName] !== undefined) {

      options[hookName].apply(this.el, args);
    }
  }

  destroy() {
    // Iterate over each matching element.
    windowGlobal.removeEventListener('resize', this.onResize);

    document.removeEventListener('click', this.onClick);
  }

  render() {
    const { children, displayReverse } = this.props;
    const { currentIndex } = this.state;
    const isFirst = displayReverse ? currentIndex === children.length - 1 : currentIndex === 0;
    const isLast = displayReverse ? currentIndex === 0 : currentIndex === children.length - 1;

    return (
      <Wrapper>
        <div
          id="timeliny"
          ref={el => this.el = el}
          style={{ direction: displayReverse ? 'rtl': 'ltr' }}
        >
          <div className="timeliny-wrapper">
            <div
              className="timeliny-timeline"
              ref={el => this.timelineEl = el}
            >
              {children}
            </div>
          </div>
        </div>

        <TimelinyDragWrapper id="dragged" ref={el => this.timelinyDrag = el}>
          <TimelinyDrag>
            <TimelinyDragItem>
              {!isFirst && <Icon src={dragArrowLeftIcon} alt="drag arrow left" />}
            </TimelinyDragItem>
            <TimelinyDragHand src={dragHandIcon} alt="drag hand" />
            <TimelinyDragItem right>
              {!isLast && <Icon src={dragArrowRightIcon} alt="drag arrow right" />}
            </TimelinyDragItem>
          </TimelinyDrag>
          <TimelinyDragSwipe>Swipe</TimelinyDragSwipe>
        </TimelinyDragWrapper>
      </Wrapper>
    );
  }
}

Timeliny.propTypes = {
  options: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  displayReverse: PropTypes.bool,
};

Timeliny.defaultProps = {
  displayReverse: 'en',
  options: {
    order: 'asc',
    className: 'timeliny',
    wrapper: '<div class="timeliny-wrapper"></div>',
    boundaries: 2,
    animationSpeed: 250,
    disableVerticalLine: true,
    hideBlankYears: false,
    draggedElement: '#dragged',
  }
};

export default Timeliny;
