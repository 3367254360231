import styled from 'styled-components';
import media from 'styles/media';
import Image from 'components/Image';
import Callout from 'components/Callout';

export const Wrapper = styled.section`
  position: relative;
  height: 900px;
  display: flex;
  flex: column;
  justify-content: center;
  width: 100%;
  overflow: hidden;

  ${media.xs`
    margin: 5rem 0;
    height: 20rem;
  `};
`;

export const StyledCallout = styled(Callout)`

  ${props =>
    props.locale === 'ru'
      ? media.lg`h2 { font-size: 10rem; line-height: 11rem; letter-spacing: -6px;}`
      : null};

  ${props =>
    props.locale === 'ru'
      ? media.portrait`h2 {font-size: 5rem; line-height: 5.5rem}`
      : null};

  ${media.xs`
    position: relative;
    z-index: 3;
  `};
`;

export const KeysWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

export const Keys = styled.div`
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  .parallax-outer:nth-child(1) {
    left: 0;
    z-index: 3;
  }

  .parallax-outer:nth-child(2) {
    left: 40%;
    z-index: 0;
  }

  .parallax-outer:nth-child(3) {
    right: 0;
    z-index: 3;
  }

  ${media.xs`
    .parallax-outer {
      position: absolute;
    }

    .parallax-outer:nth-child(1) {
      top: 0;
    }

    .parallax-outer:nth-child(2) {
      left: 38%;
      top: 12%;
    }

    .parallax-outer:nth-child(3) {
      top: 29%;
    }
  `};
`;

export const Key = styled(Image)`
  ${media.xs`
    height: 236px;
    top: 25%;
    left: 0;
  `};
`;

export default Wrapper;
