import styled from 'styled-components';
import media from 'styles/media';
import BasicImage from 'components/Image';
import {mobileImageHeight} from 'styles/variables';

export const Image = styled(BasicImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  ${media.xs`
    height: ${mobileImageHeight};
  `};
`;

export const ImageBackground = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  -webkit-background-size: cover;
  background-size: cover;
  backface-visibility: hidden;

  ${media.xs`
    height: ${mobileImageHeight};
  `};
`;

export const FullSizeImage = styled(BasicImage)`
  width: auto;
  height: auto;
  max-height: 100vh;
  max-width: 100vw;
  margin: 0 auto;
  display: block;
  transition: opacity 500ms ease-in-out;
  backface-visibility: hidden;
`;
