import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import Parallax from 'containers/Parallax';
import Figure from 'components/Figure';
import { Row, Col } from 'react-styled-flexboxgrid';
import { Inner, Outer } from 'containers/Wrapper';
import InfoBox from 'components/InfoBox';
import { Wrapper, FigureWrapper } from './styled';

const Row1Parallax = () => (
  <Outer>
    <Inner>
      <Wrapper>
        <Row>
          <Col xs={12} smOffset={3} sm={9} md={6} lg={5} lgOffset={6}>
            <InfoBox>
              <FormattedHTMLMessage id="tripartism_infoboxIlo" />
            </InfoBox>
          </Col>
          <Parallax offsetYMax={20} offsetYMin={-20}>
            <FigureWrapper>
              <Figure
                width="inherit"
                image="tripartism/three-keys"
                caption="tripartism_imageThreekeysCaption"
              />
            </FigureWrapper>
          </Parallax>
        </Row>
      </Wrapper>
    </Inner>
  </Outer>
);

export default Row1Parallax;
